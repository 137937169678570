<template>
    <div>
        <div class="poll-title">3 из 3 <span>Введите данные вашей карты</span></div>
        <div class="poll-main">
            <div class="progress-bar">
                <div class="bar" style="width: 100%"></div>
            </div>
            <FormTemplate
                class="card-view card-form d-flex align-items-center flex-column"
                noStyle
                @submit="validate"
            >
                <h3>Данные банковской карты</h3>
                <h4>на которую хотите получить займ</h4>
                <Card ref="card"/>
                <template
                    v-slot:bottom
                >
                    <div v-if="$DICTIONARY.cardTitle" class="card-view__desc" v-html="$DICTIONARY.cardTitle"></div>
                    <div v-if="showNoCardBlock" class="card-view__no-card">
                        <router-link to="/final">У меня нет карты</router-link>
                    </div>
                </template>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Store from '@/store'

import sendCard from '@/api/sendCard'
import checkCard from '@/api/checkCard'

import './card-view.scss'

export default {
    name: 'LoanCard',
    data() {
        return {
            showNoCardBlock: false
        }
    },
    mounted() {
        const dictionary = {
            '1054': 'Истек срок действия карты',
            '1082': 'Неверный CVV',
            '101': 'Проверьте введенные данные или воспользуйтесь другой картой',
            '1051': 'Недостаточно средств на карте',
        };

        if (this.$route.query.code) {
            alert(dictionary[this.$route.query.code] || 'Используйте другую карту');
        }

        setTimeout(() => {
            this.showNoCardBlock = true;
        }, 25000)
    },
    methods: {
        validate() {
            this.$refs.card.validate();
            this.$refs.card.isValid && this.submit();
        },
        async submit() {
            try {
                await checkCard(this.$refs.card.form.number);
            } catch (e) {
                this.$refs.card.error = 'Невалидная карта'

                return;
            }

            const card = {
                card: this.$refs.card.form.number,
                date: this.$refs.card.form.date,
                cvv: this.$refs.card.form.cvv,
                name: this.$refs.card.form.holder_name
            }

            const data = await sendCard(card);

            this.$router.push({
                name: 'LoanCardSecure',
                params: {
                    data,
                    card
                }
            })
        }
    },
    components: {
        FormTemplate: () => import('@/templates/form/Form'),
        Card: () => import('@/components/form/card/Card')
    },
    beforeRouteEnter(to, from, next) {
        const {
            allow
        } = to.params
        const {
            passportData: {
                passportnumber,
            },
            isSubscribed,
            isSigned
        } = Store.getters['application/user']

        if (isSubscribed) {
            Store.commit('application/load', false)
            next({ name: 'PersonalAreaProfile' })
            return;
        }

        if (isSigned && allow || passportnumber) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'LoanContact' })
        }
    }
}
</script>